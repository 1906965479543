import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./index.scss";
import axios from "axios";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// const axiosUrl: string = process.env.REACT_APP_API_URL as string; // For local use
const axiosUrl: string = "https://lagotto.ee/";

console.log(axiosUrl);

export const axiosInstance = axios.create({
  baseURL: axiosUrl,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST",
  },
});

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);
